/**
 * @author zgl
 */

export const MyImage = {
  logo: require("./vite.svg"),
  home: {
    logo: require("./home/logo.png"),
    sec1: require("./home/sec1.png"),
    sec1Bg: require("./home/sec1Bg.png"),
    sec1Star: require("./home/sec1Star.png"),
    lan: require("./home/lan.png"),
    our: require("./home/our.png"),
    high: require("./home/high.png"),
    high1: require("./home/high1.png"),
    high2: require("./home/high2.png"),
    high3: require("./home/high3.png"),
    star: require("./home/star.png"),
    twiter: require("./home/twiter.png"),
    tw1: require("./home/tw1.png"),
    tw2: require("./home/tw2.png"),
  },
  faq: {
    note: require("./faq/note.png"),
  },
  login: {
    bgTop: require("./login/pwd.png"),
  },
  mbHome: {
    drawer: require("./mbHome/drawer.png")
  }
};
